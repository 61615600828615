.delete {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;
  left: 510px;
  top: 10px;
  z-index: 999999;

  &__box {
    z-index: 22;
    width: 417px;
    height: 178px;
    background-color: #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #443E3E;
  }

  &__title {
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 40px;
  }

  &__btn {
    width: 172px;
    padding: 10px;
    border-radius: 10px;
    margin-right: 10px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }

  &-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    max-width: 100%;
    width: 100%;
    background: rgba(20, 20, 20, 0.5);
    backdrop-filter: blur(8px);
    z-index: 20;
  }
}