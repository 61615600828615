.stress {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  position: relative;
  width: max-content;


  &__buttons {
    &_elem {
      padding: 9px 37px;
      margin-right: 10px;
      cursor: pointer;
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      text-decoration: none;

      &:last-child {
        margin-right: 0;
        padding: 10.5px 37px;
      }
    }
  }

  &__list {
    color: #443E3E;
    margin-bottom: 40px;
    max-width: 100%;
    width: 100%;

    &_blocks {
      display: flex;
      margin-bottom: 20px;

      &_title {
        margin-left: 150px;
      }

      &_category {
        margin-left: 130px;
      }

      &_duration {
        margin-left: 40px;
      }

      &_description {
        margin-left: 100px;
      }
    }


    &_elem {
      display: flex;
      align-items: center;
      border: 1px solid #CCCCCC;


      & > p {
        border-radius: 7px;
        margin-right: 10px;
        margin-bottom: 0px;
      }
    }

    &_num {
      font-weight: normal;
      font-size: 18px;
      text-align: right;
      letter-spacing: 0.2px;
      color: #443E3E;
      width: 20px;
    }
    &_dots {
      width: 9px;
      height: 15px;
      margin-right: 10px;
      margin-left: 10px;
    }
    &_title {
      width: 254px;
      padding: 10px;
    }
    &_category {
      width: 105px;
      padding: 10px;
      text-align: center;
    }
    &_duration {
      width: 145px;
      text-align: center;
      padding: 10px;
    }
    &_description {
      padding: 10px;
      width: 500px;
    }

    &_btn {
      &_elem {
        height: 45.5px;
        width: 90px;
        font-size: 18px;
        border-radius: 7px;
        cursor: pointer;
        margin-right: 10px;



        &:last-child {
          margin-right: 0;
        }
      }
    }
  }


  &__modal {
    display: none;

    &-active {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      height: calc(100vh - 91px);
      max-width: 100%;
      width: 100%;
      top: 50px;
    }

    &-bg {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      background: rgba(20, 20, 20, 0.5);
      backdrop-filter: blur(8px);
      max-width: 100%;
      width: 100%;
    }
  }

  &__form {
    transform: translateX(-150px);
    width: 450px;
    // height: 570px;
    background-color: #FFFFFF;
    z-index: 2;
    padding: 20px 42px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_title {
      font-weight: normal;
      font-size: 18px;
      color: #000000;
      margin-bottom: 10px;
    }

    &_inputTitle {
      border: none;
      border-bottom: 1px solid #E6E6E6;
      padding-bottom: 8px;
      width: 100%;
      margin-bottom: 24px;
    }

    &_category {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      &_title {
        font-weight: normal;
        font-size: 18px;
        color: #625F6E;
        margin-bottom: 10px;
      }

      &_label {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        color: #625F6E;
        width: 18px;
        height: 18px;
        border: 2px solid #443E3E;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .checkmark {
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          border-radius: 50%;
          background-color: #443E3E;
          display: none;
        }

        & > input:checked + .checkmark {
          display: inline-block;
        }

        &:last-child {
          margin-right: 0;
        }

        & > input {
          display: none;
        }
      }
    }

    &_file {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &_fileInput {
      width: 195px;
      margin: 0 auto;
      padding: 10px 0;
      text-align: center;
    }

    input[type="file"] {
      display: none;
    }


  }

  &__inputs {
    width: 100%;
    margin-bottom: 20px;

    &_elem {
      border: none;
      border-bottom: 1px solid #E6E6E6;
      padding-bottom: 8px;
      margin-bottom: 24px;
    }
  }

  &__save {
    width: 100%;

    &_btn {
      cursor: pointer;
      padding: 10px 0;
      max-width: 172px;
      width: 100%;
      margin-right: 20px;
      font-weight: bold;
      font-size: 18px;
      font-family: 'Poppins', sans-serif;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__change {
    right: 20px;

    &_btn {
      padding: 8px 9.5px;
      margin-right: 16px;
      font-size: 18px;
      font-family: 'Poppins', sans-serif;
      cursor: pointer;

      :last-child {
        margin-right: 0;
      }
    }
  }
}


input {
  outline: none;
  &::placeholder {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    color: #242426;
  }
}

.blue {
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #1BBDD4;
  color: #1BBDD4;
  transition: all .3s;

  &:hover {
    box-shadow: 0 0 5px 3px rgba(27,189,212,0.5);
  }
}

.danger {
  background-color: #FF6A6D;
  border: 1px solid #FF6A6D;
  color: #FFFFFF;
  transition: all .3s;

  &:hover {
    box-shadow: 0 0 5px 3px rgba(183, 30, 38, 0.8);
  }
}


.btn-blue[disabled] {
  pointer-events: none;
  opacity: 0.3;
}

.btn-white[disabled] {
  pointer-events: none;
  opacity: 0.3;
}

.table-wrap {
  width: calc(100vw - 320px);
  min-width: 800px;
}

