@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  height: 100%;
}

input {
  font-family: Poppins, sans-serif;
}

.container {

}

.inner-container {
  padding: 24px;
}

.title {
  width: 150px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #443E3E;
  line-height: 36px;
  margin: 30px auto 30px 0;
}

.title-secondary {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #443E3E;
  margin-bottom: 10px;
}

.btn-blue {
  background: #1BBDD4;
  border-radius: 10px;
  border: 1px solid #1BBDD4;
  color: #FFFFFF;
  transition: all .3s;

  &:hover {
    box-shadow: 0 0 5px 3px rgba(27,189,212,0.5);
  }
}

.delete-blue {
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #1BBDD4;
  color: #1BBDD4;
  transition: all .3s;

  &:hover {
    box-shadow: 0 0 5px 3px rgba(27,189,212,0.5);
  }
}

.btn-white {
  background: #FFFFFF;
  border-radius: 10px;
  color: #1BBDD4;
  border: 1px solid #1BBDD4;
  transition: all .3s;

  &:hover {
    background-color: #1BBDD4;
    color: #FFFFFF;
  }
}

.btn-danger {
  background: #FFFFFF;
  border-radius: 10px;
  color: #FF6A6D;
  border: 1px solid #FF6A6D;
  transition: all .3s;

  &:hover {
    background-color: #FF6A6D;
    color: #FFFFFF;
  }
}
