.login {
  max-width: 100%;
  width: 100%;
  height: 100vh;
  background: url("./../../assets/images/login/loginBackground.svg") no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat, sans-serif;
  position: fixed;
  left: 0;
  top: 0;

  &-form {
    height: auto;
    background: linear-gradient(120.59deg, rgba(115, 230, 245, 0.4) 0%, rgba(16, 85, 95, 0.4) 51.86%, rgba(4, 50, 56, 0.4) 80.11%), rgba(10, 138, 155, 0.6);
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 0;


    &-submit {
      width: 425px;
      &__title {
        font-size: 36px;
        font-weight: bold;
        margin: 0 auto 34px 0;
        text-align: start;
      }

      &-inputs {
        display: flex;
        flex-direction: column;

        &__label {
          font-size: 14px;
          margin-bottom: 4px;
        }

        &__elem {
          background: #167587;
          border: 1px solid #FFFFFF;
          border-radius: 10px;
          width: 389px;
          height: 40px;
          margin-bottom: 20px;
          padding-left: 10px;
          outline: none;
          color: #FFFFFF;
          font-family: Poppins, sans-serif;
        }

        &__error {
          font-size: 14px;
          margin-top: -18px;
          margin-bottom: 10px;
          font-weight: bold;
          color: #d2d24e; // save this color
        }
      }

      &-socials {
        display: flex;
        margin-bottom: 50px;

        &__box {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 9px;
          border: 1px solid #FFFFFF;
          border-radius: 7px;
          margin-right: 16px;
        }

        &__forgot {
          color: #FFFFFF;
          text-decoration: none;
          font-weight: bold;
          font-size: 14px;
          margin-left: 128px;
        }
      }
      &-logIn {
        font-size: 16px;
        display: flex;
        align-items: center;

        & > p {
          margin-right: 5px;
        }

        & > a {
          color: #FFFFFF;
          font-weight: bold;
          text-decoration: none;
          margin-right: 25px;
        }

        &__btn {
          font-size: 24px;
          letter-spacing: -0.015em;
          padding: 15px 37px;
          background-color: Transparent;
          border: #FFFFFF 1px solid;
          color: #FFFFFF;
          border-radius: 7px;
        }
      }
    }
  }
}