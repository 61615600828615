.profile {
  background: url('../../assets/images/profile-bg.svg') no-repeat;
  background-size: cover;
  width: calc(100% - 255px);
  height: calc(100vh - 91px);
  display: flex;
  justify-content: center;
  align-items: center;

  &__form {
    width: 900px;
    padding: 40px 40px 20px 40px;

    &-inner {
      display: flex;
      justify-content: space-between;
    }

    &-inputs {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 24px;
    }

    &-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #443E3E;
      margin-bottom: 16px;
    }

    &-input {
      border: 1px solid #CCCCCC;
      border-radius: 15px;
      outline: none;
      padding: 12px 32px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #443E3E;
    }

    &-input::-webkit-outer-spin-button,
    &-input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &-input[type=number] {
      -moz-appearance: textfield;
    }

    &-right,
    &-left{
      width: 362px;
    }

    &-btn {
      width: 234px;
      padding: 12px 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 15px;
      border: none;
      background: #1BBDD4;
      border-radius: 100px;
      color: #fff;
      margin: 0 auto;
      margin-top: 50px;
      display: block;

    }
  }
}