.dashboard {
  background: #006674;
  width: 290px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;

  &__link:hover {
    color: #FFFFFF;
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 15px 13px;
    color: #CCCCCC;
    text-decoration: none;
    transition: all .1s;
    cursor: pointer;

    &-arrow {
      transform: translateX(45px) rotate(180deg);
      transition: all .3s;

      &-active {
        transform: translateX(45px) rotate(360deg);
        transition: all .3s;
      }
    }

    &-title {
      margin-left: 16px;
    }

    &-active {
      color: #FFFFFF;
      border-left: 3px solid #fff;
      background: rgba(255, 255, 255, 0.3);
    }
  }

  &__tests {
    display: none;

    &-active {
      display: block;
    }

    &_link {
      text-decoration: none;
      color: #CCCCCC;
      padding: 8.5px 20px;
      display: flex;
      align-items: center;
      margin-top: 8px;

      &-active {
        padding: 8.5px 20px;
        color: #FFFFFF;
        border-left: 3px solid #fff;
        background: #1F717C;
        margin-top: 8px;
      }
    }
  }

  &__quite {
    display: flex;
    align-items: center;
    padding: 15px 13px;
    position: absolute;
    margin: 30% 0;

    &_text {
      color: #CCCCCC;
      cursor: pointer;
      margin-left: 21px;
    }
    &_text:hover {
      color: #FFFFFF;
    }
  }
}