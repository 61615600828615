.edit {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;
  left: 510px;
  right: 0;
  top: 91px;

  &__box {
    z-index: 22;
    width: 417px;
    height: 390px;
    background-color: #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #443E3E;
    transform: translate(-150px, -50px);
  }

  &__title {
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 10px;
  }

  &__btn {
    width: 172px;
    padding: 10px;
    border-radius: 10px;
    margin-right: 10px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }

  &__form {
    &_label{


      &-checkbox {
        flex-direction: row;
      }
    }

    &_input {
      width: 360px;
      height: 24px;
      border: none;
      border-bottom: 1px solid #E5E5E5;
      margin-bottom: 20px;
      margin-top: 15px;
      outline: none;

      &-textarea {
        border: none;
        border-bottom: 1px solid #E5E5E5;
        outline: none;
        margin-bottom: 16px;
      }
    }

    &_checkbox {
      margin-right: 18px;
    }
  }

  &-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    max-width: 100%;
    width: 100%;
    background: rgba(20, 20, 20, 0.5);
    backdrop-filter: blur(8px);
    z-index: 12;
  }
}