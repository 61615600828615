.header {
  background: #006674;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 25px;

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  &__nickname {
    font-size: 12px;
    line-height: 24px;
    margin-left: 10px;
  }
}

.logo {
  width: 46px;
  height: 43px;

  &-title {
    font-family: 'Mulish', sans-serif;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #FFFEFE;
    margin-left: 8px;
  }
}