.passing {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__description {
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #443E3E;
    width: 660px;
    margin-bottom: 10px;
  }

  &__level {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;

    & > span {
      font-size: 20px;
      line-height: 30px;
      color: #443E3E;
    }
  }

  &__content {
    position: relative;
    margin-top: 20px;

    & > img {
      height: 500px;
      width: 850px;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    background-color: #1998A7;
    border-radius: 50%;
    cursor: pointer;

    &-inactive {
      cursor: not-allowed;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55px;
      height: 55px;
      background-color: #F3F3F3;
      border-radius: 50%;
    }
  }
}

.before {
  position: absolute;
  left: -60px;
  top: 45%;
  transform: rotate(-90deg);
}

.next {
  position: absolute;
  right: -60px;
  top: 45%;
  transform: rotate(90deg);
}