.pie {
  height: 580px;
  width: 288px;
  padding: 16px 16px 40px 16px;
  color: #000000;

  &__title {
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    font-size: 24px;
    color: #333333;
  }
}

.main-pieChart {
  width: 288px!important;
}