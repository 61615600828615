.add {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  left: 510px;
  z-index: 9999;

  &__box {
    z-index: 9999;
    width: 417px;
    height: 380px;
    background-color: #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #443E3E;
  }

  &__title {
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 10px;
  }

  &__btn {
    width: 172px;
    padding: 14px;
    border-radius: 10px;
    margin-right: 10px;
    cursor: pointer;
    font-weight: bold;

    &:last-child {
      margin-right: 0;
    }
  }

  &-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    max-width: 100%;
    width: 100%;
    background: rgba(20, 20, 20, 0.5);
    backdrop-filter: blur(8px);
    z-index: 99;
  }

  &__form {
    margin-bottom: 50px;
    &_label{
      display: flex;
      flex-direction: column;

      &-checkbox {
        flex-direction: row;
      }
    }

    &_input {
      width: 360px;
      height: 24px;
      border: none;
      border-bottom: 1px solid #E5E5E5;
      margin-bottom: 20px;
      margin-top: 15px;
      font-family: Poppins, sans-serif;
      font-size: 16px;
    }

    &_checkbox {
      margin-right: 18px;
    }
  }

}