.main {
  display: flex;
  //justify-content: space-around;

  &-pieChart {
    border: 1px solid #999999;
    border-radius: 20px;
    width: 330px;
    margin-right: 50px;
  }

  &-choropleth {
    width: 631px;
    height: auto;
  }
}