.delete-icon {
    position: absolute !important;
    right: 0;
    top: 8px;
    // height: 40px;
    width: 40px;
}
.delete-icon:hover {
    transform: scale(1.01);
    cursor: pointer;
    opacity: .6;
}
.chip-icon {
  display: flex;
  width: 24px;
  align-items: center;
  height: 10px;
  margin-right: 4px !important;
  padding: 7px
}
.select-wrap {
 max-width: 628px;
 width: 536px;
 height: 58px;
}

.input-groups-wrap {
    display: flex;
    flex-direction: column;
    width: inherit;
}

.form-control {
  position: relative;
  width: 600px;
}

.btn-position {
    margin-right: 55px !important;
  }

.stimulus-title {
  font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 30px;
color: #006674;
margin-bottom: 20px;
margin-top: 25px;
}

.type-value {
  font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
color: #443E3E;
margin-bottom: 16px;
}

.chip-style {
  height: 24px !important;
  background: #CCCCCC !important;
}
.input-row {
  margin-right: 20px;
  width: 100% !important;
  max-width: 536px;
}

#demo-multiple-chip {
  padding: 12px 11px !important;
}