.show {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;
  left: 510px;
  right: 0;
  top: 91px;

  &__box {
    z-index: 22;
    padding: 24px;
    width: 885px;
    height: 357px;
    background-color: #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #443E3E;
    transform: translate(-150px, -50px);

    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;
    }
  }

  &__title {
    font-weight: normal;
    font-size: 18px;
    margin-left: 26px;
  }

  &__text {
    width: 785px;
    font-size: 16px;
    line-height: 24px;
    color: #443E3E;
    margin-bottom: 16px;
    word-wrap: break-word;
  }

  &__percent {
    color: #006674;
    font-size: 22px;
    font-weight: 500;
    width: 785px;
    text-align: right;
    margin-bottom: 16px;
  }

  &__bar {
    width: 785px;
    height: 70px;
    border-radius: 5px;
    border: 2px solid #EEEEEE;
    margin-bottom: 16px;
  }

  &__effected {
    width: 785px;
    display: flex;
    justify-content: space-between;

    &_text {
      font-size: 18px;
    }
  }

  &-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    max-width: 100%;
    width: 100%;
    background: rgba(20, 20, 20, 0.5);
    backdrop-filter: blur(8px);
    z-index: 12;
  }
}